export const EMPTY_CURRENCY = '';
export const NOT_CRYPTO_CURRENCIES = ['AFN','ALL','DZD','USD','EUR','AOA','XCD','ARS','AMD','AUD','AWG','AZN','BSD','BHD','BDT','BBD','BYN','BZD','XOF','BMD','INR','BTN','BOB','BOV','BAM','BWP','BRL','BND','BGN','BIF','CVE','KHR','XAF','CAD','KYD','CLP','CLF','CNY','COP','COU','KMF','CDF','NZD','CRC','HRK','CUP','CUC','ANG','CZK','DKK','DJF','DOP','EGP','SVC','ERN','ETB','FJD','FKP','FOK','GMD','GEL','GHS','GIP','GTQ','GBP','GNF','GYD','HTG','HNL','HKD','HUF','ISK','IDR','XDR','IRR','IQD','ILS','JMD','JPY','JOD','KZT','KES','KPW','KRW','KWD','KGS','LAK','LBP','LSL','ZAR','LRD','LYD','CHF','MOP','MGA','MWK','MYR','MVR','MRU','MUR','XUA','MXN','MXV','MDL','MNT','MAD','MZN','MMK','NAD','NPR','NIO','NGN','OMR','PKR','PAB','PGK','PYG','PEN','PHP','PLN','QAR','RON','RUB','RWF','SHP','WST','STN','SAR','RSD','SCR','SLL','SGD','ANG','XSU','SBD','SOS','SSP','LKR','SDG','SRD','SEK','CHF','SYP','TWD','TJS','TZS','THB','TOP','TTD','TND','TRY','TMT','UGX','UAH','AED','USN','UYU','UYI','UZS','VUV','VEF','VES','VND','YER','ZMW','ZWL'];
export const CRYPTO_CURRENCIES_LIST = {
    'DOGE': {
        'title': 'DOGE',
        'longTitle': 'Dogecoin'
    },
    'BTC': {
        'title': 'BTC',
        'longTitle': 'Bitcoin'
    },
    'LTC': {
        'title': 'LTC',
        'longTitle': 'Litecoin'
    },
    'ETH': {
        'title': 'ETH',
        'longTitle': 'Ethereum (ETH)'
    },
    'DASH': {
        'title': 'DASH',
        'longTitle': 'Dash'
    },
    'XMR': {
        'title': 'XMR',
        'longTitle': 'Monero'
    },
    'BCH': {
        'title': 'BCH',
        'longTitle': 'Bitcoin Cash'
    },
    'NAFTY': {
        'title': 'NAFTY',
        'longTitle': 'NAFTY'
    },
    'BNB': {
        'title': 'BNB',
        'longTitle': 'BNB Smart Chain'
    },
    'TRX': {
        'title': 'TRX',
        'longTitle': 'TRX'
    },
    'USDT_ETH': {
        'title': 'ETH / USDT',
        'longTitle': 'USDT Ethereum (ERC20)'
    },
    'USDT_BNB': {
        'title': 'BNB / USDT',
        'longTitle': 'USDT BNB Smart Chain (BEP20)'
    },
    'USDT_TRX': {
        'title': 'TRX / USDT',
        'longTitle': 'USDT Tron (TRC20)'
    }
}

// export const CRYPTO_CURRENCIES = ['DOGE', 'BTC', 'LTC', 'ETH', 'DASH', 'XMR', 'BCH', 'NAFTY', 'BNB', 'TRX', 'USDT_ETH', 'USDT_BNB', 'USDT_TRX'];
export const CRYPTO_CURRENCIES = Object.keys(CRYPTO_CURRENCIES_LIST)
